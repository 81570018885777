import { getCookie, setCookie, removeCookie } from './cookie';

class Session {
    static session;
    _auth;
    _currentModule;
    _currentCompany;

    static shared() {
        if (this.session == null) {
            this.session = new Session();
        }
        return this.session;
    }

    constructor() {
        this._auth = getCookie('tkn');
        this._currentCompany = getCookie('cmpn');
        this._currentModule = getCookie('mdl');
    }

    createSession(token) {
        this._auth = token;
        setCookie('tkn', token);
    }

    removeSession() {
        this._auth = null;
        this._currentModule = null;
        this._currentCompany = null;
        removeCookie('tkn');
        removeCookie('cmpn');
        removeCookie('mdl');
    }

    isLoguedIn() {
        return !!this._auth;
    }

    changeCurrentCompany(id) {
        setCookie('cmpn', id);
    }

    changeCurrentModule(url) {
        setCookie('mdl', url);
    }
}

export default Session;
