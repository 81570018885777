import React, { Component } from "react";
import { HashRouter, Route, Switch, Redirect } from "react-router-dom";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import "./App.scss";
import Session from "./helpers/session";

const loading = () => (
  <div className="animated fadeIn pt-3 text-center">
    <span
      className="spinner-border spinner-border-sm"
      role="status"
      aria-hidden="true"
    ></span>
    Cargando...
  </div>
);
const Layout = React.lazy(() => import("./views/Layout"));
const Login = React.lazy(() => import("./views/Login"));
const Page404 = React.lazy(() => import("./views/404"));

class App extends Component {
  render() {
    return (
      <HashRouter>
        <React.Suspense fallback={loading()}>
          <Switch>
            <Route
              exact
              path="/login"
              name="Login"
              render={props => {
                const session = Session.shared();
                if (session.isLoguedIn()) {
                  return <Redirect to="/" />;
                }
                return <Login {...props} />;
                
              }}
            />
            <Route
              exact
              path="/404"
              name="Page 404"
              render={props => <Page404 {...props} />}
            />
            <Route
              path="/"
              name="Home"
              render={props => {
                const session = Session.shared();
                if (!session.isLoguedIn()) {
                  return <Redirect to="/login" />;
                }
                return <Layout {...props} />;
              }}
            />
          </Switch>
        </React.Suspense>
      </HashRouter>
    );
  }
}

export default App;
